const terminal = document.getElementById("terminal") as HTMLDivElement;
const compatibilityButton = document.getElementById("compatibilityButton") as HTMLButtonElement;
const flashButton = document.getElementById("flashNowButton") as HTMLButtonElement;
const verifyProgressBar = document.getElementById("verifyProgress") as HTMLDivElement;
const flashProgressBar = document.getElementById("flashProgress") as HTMLDivElement;
const appDiv = document.getElementById("appDiv") as HTMLDivElement;
const errorDiv = document.getElementById("errorDiv") as HTMLDivElement;
// const accordionButton1 = document.getElementById("accordionButton1") as HTMLButtonElement;
const accordionButton2 = document.getElementById("accordionButton2") as HTMLButtonElement;
const accordionButton3 = document.getElementById("accordionButton3") as HTMLButtonElement;
const step1NextButton = document.getElementById("step1NextButton") as HTMLButtonElement;
const step2NextButton = document.getElementById("step2NextButton") as HTMLButtonElement;
const detectionSuccess = document.getElementById("detectionSuccess") as HTMLDivElement;
const flashSuccess = document.getElementById("flashSuccess");
const flashProgressDiv = document.getElementById("flashProgressDiv");
const verifyProgressSpinner = document.getElementById("verifyProgressSpinner");
const flashProgressSpinner = document.getElementById("flashProgressSpinner");
const detectionError = document.getElementById("detectionError");
const flashError = document.getElementById("flashError");
// const  = document.getElementById("");

import { ESPLoader, FlashOptions, LoaderOptions, Transport } from "esptool-js"
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import * as CryptoJS from 'crypto-js';

const term = new Terminal;
term.open(terminal);

let device = null;
let transport: Transport = null;
let chip: string = null;
let esploader: ESPLoader;
let timerId;
const fileArray = [];

//set up of GUI elements
terminal.style.display = 'none';
compatibilityButton.disabled = true;
flashButton.disabled = true;
detectionSuccess.style.display = 'none';
flashSuccess.style.display = 'none';
flashProgressDiv.style.display = 'none';
detectionError.style.display = 'none';
flashError.style.display = 'none';
flashProgressSpinner.style.display = 'none';
verifyProgressSpinner.style.display = 'none';
setProgress(flashProgressBar, 0);
setProgress(verifyProgressBar, 0);

//terminal setup
const espLoaderTerminal = {
  clean() {
    term.clear();
  },
  writeLine(data) {
    term.writeln(data);
  },
  write(data) {
    term.write(data);
  },
};

document.addEventListener('DOMContentLoaded', () => {
    if (!isCompatibleBrowser()) {
        // Display a message indicating that the browser is not compatible
        let message = 'Your browser is not supported. Please use Chrome, Edge, or Safari.';
        console.error(message);
        appDiv.style.display = 'none';
        errorDiv.style.display = 'block';
        errorDiv.textContent = message;
    }
});

function isCompatibleBrowser(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    // Check for Chrome, Edge, or Safari
    const isChromeOrEdgeOrSafari = /chrome|edge|safari/.test(ua);

    // Feature detection for File API
    const isFileApiSupported = window.File && window.FileReader && window.FileList && window.Blob;

    // Feature detection for Fetch API
    const isFetchApiSupported = 'fetch' in window;

    return isChromeOrEdgeOrSafari && isFileApiSupported && isFetchApiSupported;
}

function setProgress(progressBar: HTMLDivElement, progress: number, background: string | undefined = '') {
    const roundedProgress = Math.round(progress);

    progressBar.style.width = roundedProgress + '%';
    progressBar.setAttribute('aria-valuenow', roundedProgress.toString());
    progressBar.textContent = roundedProgress + '%';
    progressBar.className = 'progress-bar';

    if (background !== '') {
        progressBar.classList.add(background);
    }

    console.log(progressBar.id + ' progress: ' + roundedProgress);
}

async function selectPort(): Promise<boolean> {
    if (device === null) {
        try {
            const portFilters = [
                { usbVendorId: 0x10C4, usbProductId: 0xEA60 },
            ];
            // @ts-ignore
            device = await navigator.serial.requestPort({ filters: portFilters });
            console.log('selected serial device: ' + device);
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    if (transport === null) {
        console.log('instantiate transport');
        transport = new Transport(device, true);
    }
    return true;
}

async function reboot() {
    await selectPort();

    console.log("reboot:" + transport);
    await transport.connect();
    await transport.setDTR(false);
    await new Promise((resolve) => setTimeout(resolve, 100));
    await transport.setDTR(true);
}

async function finish() {
    if (transport) await transport.disconnect();

    device = null;
    transport = null;
    chip = null;
}

function handleTimeout() {
    console.error('The process took too long to complete.');

    setProgress(verifyProgressBar, 100, 'bg-danger');
    detectionError.style.display = 'block';
    verifyProgressSpinner.style.display = 'none';

    finish();
}

step1NextButton.onclick = () => {
    compatibilityButton.disabled = false;
    accordionButton2.click();
}

step2NextButton.onclick = () => {
    accordionButton3.click();
}

flashButton.onclick = async () => {
    setProgress(flashProgressBar, 0);
    
    await doFlash();
}

compatibilityButton.onclick = async () => {
    setProgress(flashProgressBar, 0);
    setProgress(verifyProgressBar, 0);

    //select the port
    if (!await selectPort()) {
        console.error('No port selected');
        return;
    }

    verifyProgressSpinner.style.display = 'inline-block';
    compatibilityButton.disabled = true;
    setProgress(verifyProgressBar, 50);

    try {
        const loaderOptions: LoaderOptions = {
            transport,
            baudrate: 921600,
            terminal: espLoaderTerminal
        } as LoaderOptions;
        esploader = new ESPLoader(loaderOptions);

        timerId = setTimeout(handleTimeout, 8000);

        chip = await esploader.main();
        console.log("after check");
    } catch (e) {
        console.error(e);
        term.writeln(`Error: ${e.message}`);
    }

    console.log("Settings done for :" + chip);

    if (chip.startsWith('ESP32')) {
        console.log('Nice, It\'s the right chip');
        clearTimeout(timerId);

        setProgress(verifyProgressBar, 100, 'bg-success');

        detectionSuccess.style.display = 'block';

        getFiles();
    }
    else {
        setProgress(verifyProgressBar, 100, 'bg-danger');
        console.log('Could not verify it\'s the right chip');
    }
    
    verifyProgressSpinner.style.display = 'none';
}

async function getFiles(): Promise<boolean> {
    //reset the array
    fileArray.length = 0;

    //get firmware file
    const file1Url = 'https://glprodiotfirmware.blob.core.windows.net/esp-irrigation-controller/controller-firmware-latest.bin';
    await fetch(file1Url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log("Successfully downloaded firmware file");
            return response.blob();
        })
        .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const binaryString = reader.result;
                    // Do something with the binary string
                    // console.log(binaryString);
                    fileArray.push({ data: binaryString, address: 0x20000 });
                }
            };
            reader.readAsBinaryString(blob);
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            return false;
        });
    
    //get ota file
    const file2Url = "https://glprodiotfirmware.blob.core.windows.net/esp-irrigation-controller/ota_data_initial.bin";
    await fetch(file2Url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const binaryString = reader.result;
                    fileArray.push({ data: binaryString, address: 0x10000 });

                    compatibilityButton.disabled = true;
                }
            };
            reader.readAsBinaryString(blob);
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            return false;
        });
    
    flashButton.disabled = false;
    return true;
}

async function doFlash(): Promise<boolean> {
    let error = false;

    try {
        flashButton.disabled = true;
        flashProgressDiv.style.display = 'block';
        flashProgressSpinner.style.display = 'inline-block';
        flashError.style.display = 'none';

        const flashOptions: FlashOptions = {
            fileArray: fileArray,
            flashSize: "keep",
            eraseAll: false,
            compress: true,
            reportProgress: (fileIndex, written, total) => {
                setProgress(flashProgressBar, (written / total) * 100, 'bg-primary');
            },
            calculateMD5Hash: (image) => CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
        } as FlashOptions;
        await esploader.writeFlash(flashOptions);
    } catch (e) {
        console.error(e);
        term.writeln(`Error: ${e.message}`);
        flashButton.disabled = false;
        error = true;
    } finally {
        flashProgressSpinner.style.display = 'none';

        if (error) {
            setProgress(flashProgressBar, 100, 'bg-danger');
            
            flashError.style.display = 'block';
        } else {
            console.log("SUCCESS!");

            flashSuccess.style.display = 'block';
            setProgress(flashProgressBar, 100, 'bg-success');

            //disconnect from controller
            await transport.disconnect();

            //reboot controller
            await reboot();

            //disconnect
            await finish();
        }
    }
    
    flashProgressSpinner.style.display = 'none';
    return true;
}